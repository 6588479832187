<template>
    <div class='account_manage'>
        <!-- 标题 -->
        <div class="vue_title">
            <span @click="back"><i class="el-icon-arrow-left"></i>返回</span>
            <p> 课程目录</p>
        </div>
        <div class="account_main">
            <!--  -->
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addEditBtn(1,'')" size="small" icon="el-icon-plus">新增</el-button>
                    <!-- <el-input v-model="roleValue" size="small" @keyup.enter.native="search" @clear='search' :clearable="true" placeholder="搜索"></el-input> -->
                </div>
                <!-- <div class="cond_right">
                    <el-button type="primary" class="btnBgColor_grey" size="small" icon="el-icon-upload2" @click='exportBtn'>导出</el-button>
                </div> -->
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" ref="multipleTable" @selection-change="handleSelectionChange" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <!-- <el-table-column type="selection" align="center" width="55"></el-table-column> -->
                    <el-table-column prop="id" align="center" label="ID" width="100"></el-table-column>
                    <el-table-column prop="title" label="标题"></el-table-column>
                    <el-table-column prop="total_points" label="总分"></el-table-column>
                    <el-table-column prop="duration" label="时长"></el-table-column>
                    <el-table-column prop="multiple_choice" label="单选分数"></el-table-column>
                    <el-table-column prop="much_choice" label="多选分数"></el-table-column>
                    <el-table-column class-name="last_class" label="操作" fixed="right" width="160">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="openList(scope.row.id)">活动课程</el-button>
                            <el-button type="text" size="small" @click="addEditBtn(2,scope.row)">编辑</el-button>
                            <el-button type="text" size="small" @click="delBtn(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="accountDel" @close='closeListBtn' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">删除该账号后不能再登录，确定要删除该账号吗？</div>
            </div>
        </point-out>
        <!-- 新增/编辑 -->
        <course-add ref="dialog" :type="type" :activity_id="id" @submit='submitBtn'></course-add>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import CourseAdd from '../components/course_add/index.vue'//新增/编辑
export default {
    components: { PointOut, EmptyData, PagingPage, CourseAdd },
    props: {},
    data() {
        return {
            roleValue: '',
            //
            list: [],
            loading: false,
            ids: [],
            multipleSelection: [],
            //
            page: 1,
            limit: 10,
            total: 0,
            // 删除
            del_index: '',
            type: 1,
            id: '',
            catalogue_id: '',
        }
    },
    computed: {},
    watch: {},
    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.search()
        }
    },
    mounted() { },
    methods: {
        search() {
            this.page = 1
            this.list = []
            this.total = 0
            this.getList()
        },
        // 列表
        getList() {
            var that = this
            that.loading = true
            var params = {
                activity_id: that.id,
                page: that.page,
                limit: that.limit,
            }
            that.$activityApi.catalogList(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.list = res.result?.data
                    that.total = res.result?.total
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 活动课程
        openList(id) {
            this.$router.push({
                name: 'activity_course',
                query: {
                    id: id
                }
            })
        },
        // 新增/编辑
        addEditBtn(type, info) {
            this.type = type
            this.$refs.dialog.openDialogBtn(info)
        },
        // 表单选择
        handleSelectionChange(val) {
            this.ids = []
            val.forEach(item => {
                this.ids.push(item.id)
            })
            this.multipleSelection = val
        },
        // 删除
        delBtn(index) {
            this.del_index = index
            this.$refs.dialogTip.openDialogBtn()
        },
        closeListBtn() {
            var that = this
            if (that.del_index !== '') {
                that.$activityApi.catalogDel(that.list[that.del_index].id).then(res => {
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.getList()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
        // 新增/编辑=确定
        submitBtn() {
            this.getList()
        },
        // 导出
        exportBtn() {
            var that = this
            var params = {
                role_id: that.roleValue,
                mobile: that.mobile,
                ids: that.ids.join(','),
            }
            that.$purviewApi.userExport(params).then(res => {
                return
            })
        },
        back() {
            this.$router.back()
        },
    },
}
</script>

<style lang='scss' scoped>
.account_manage {
    width: 100%;
    .account_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
            .cond_right {
                .el-button {
                    padding: 8px 10px;
                }
            }
        }
    }
}
</style>
