<template>
    <div class='account_add_edit zc_dialog_box'>
        <el-dialog :visible.sync="dialogVisible" width="534px" :close-on-click-modal='false' @close='cancelBtn'>
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="inv_main">
                <div class="main_text">
                    <div class="left"><span>*</span>标题</div>
                    <div class="right">
                        <el-input v-model="info.title" maxlength="15" placeholder="请输入标题">
                        </el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>总分</div>
                    <div class="right">
                        <el-input v-model="info.total_points" @input="limitNum($event,'total_points')" maxlength="10" placeholder="请输入总分">
                        </el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>时长</div>
                    <div class="right">
                        <el-input v-model="info.duration" @input="limitNum($event,'duration')" maxlength="10" placeholder="请输入时长">
                        </el-input>
                    </div>
                </div>

                <div class="main_text">
                    <div class="left"><span>*</span>单选分数</div>
                    <div class="right">
                        <el-input v-model="info.multiple_choice" @input="limitNum($event,'multiple_choice')" maxlength="10" placeholder="请输入单选分数">
                        </el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>多选分数</div>
                    <div class="right">
                        <el-input v-model="info.much_choice" @input="limitNum($event,'much_choice')" maxlength="10" placeholder="请输入多选分数">
                        </el-input>
                    </div>
                </div>
            </div>
            <div slot="footer" style="padding: 0 20px;">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        type: {
            type: Number,
            defaul: 1
        },
        activity_id: {
            type: [Number, String],
            defaul: 0
        },
    },
    data() {
        return {
            dialogVisible: false,
            info: {
                title: "",
                total_points: "",
                duration: "",
                multiple_choice: "",
                much_choice: "",
            },
            id: '',
            btnLoading: false
        }
    },
    computed: {
        title() {
            return this.type == 1 ? '添加目录' : '编辑目录'
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(val) {
            if (val) {
                this.info = {
                    activity_id: val.activity_id,
                    title: val.title,
                    total_points: val.total_points,
                    duration: val.duration,
                    multiple_choice: val.multiple_choice,
                    much_choice: val.much_choice,
                }
                this.id = val.id
            }
            this.dialogVisible = true
        },
        // 取消
        cancelBtn() {
            this.info = {
                title: "",
                total_points: "",
                duration: "",
                multiple_choice: "",
                much_choice: "",
            }
            this.dialogVisible = false
        },
        // 确定
        affirmBtn() {
            var that = this
            if (that.info.title == '') {
                that.$errMsg('请输入标题')
                return
            } else if (that.info.total_points == '') {
                that.$errMsg('请输入总分')
                return
            } else if (that.info.duration == '') {
                that.$errMsg('请输入时长')
                return
            } else if (that.info.multiple_choice == '') {
                that.$errMsg('请输入单选分数')
                return
            } else if (that.info.much_choice == '') {
                that.$errMsg('请输入多选分数')
                return
            } else if (that.type == 1) {
                // 添加
                that.btnLoading = true
                var params = {
                    ...this.info,
                    activity_id: this.activity_id,
                }
                that.$activityApi.catalogAdd(params).then(res => {
                    that.btnLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('submit')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.type == 2) {
                // 编辑
                that.btnLoading = true
                var params = {
                    ...this.info,
                    activity_id: this.activity_id,
                }
                that.$activityApi.catalogEdit(params, that.id).then(res => {
                    that.btnLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('submit')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 输入框限制
        limitNum(e, type) {
            if (type == 'total_points') {
                this.info.total_points = e.replace(/[^0-9.]/g, '')
            }
            if (type == 'duration') {
                this.info.duration = e.replace(/[^0-9.]/g, '')
            }
            if (type == 'multiple_choice') {
                this.info.multiple_choice = e.replace(/[^0-9.]/g, '')
            }
            if (type == 'much_choice') {
                this.info.much_choice = e.replace(/[^0-9.]/g, '')
            }
        },
    },
}
</script>

<style lang='scss' scoped>
.account_add_edit {
    .inv_main {
        width: 100%;
        padding: 10px 20px 0 0;

        .main_text {
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;

            .left {
                width: 90px;
                line-height: 40px;
                text-align: right;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;

                span {
                    color: #e00c25;
                }
            }

            .right {
                width: 370px;

                .el-input {
                    width: 100%;
                }

                .el-select {
                    width: 100%;
                }
                .el-cascader {
                    width: 100%;
                }
                .right_txt {
                    padding-top: 10px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ff2b2b;
                }

                .r_img {
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                    border: 1px solid #e8eaec;
                    background-color: #f5f7fa;
                    border-radius: 5px;
                    text-align: center;
                    .r_img_no {
                        padding-top: 20px;
                        font-size: 14px;
                        color: #94979f;
                        i {
                            font-size: 18px;
                        }
                        div {
                            padding-top: 5px;
                        }
                    }
                }
            }
        }
        .main_text:last-child {
            margin-bottom: 0;
        }
    }
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}

::v-deep .el-switch {
    margin-top: 8px;
}
</style>
